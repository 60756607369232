import firebase from 'firebase'

export default {
  async getImgUrl(storageImgPath){
    var ret
    await firebase.storage().ref(storageImgPath).getDownloadURL().then(url => {
      ret = url
    })
    return ret
  },
}
